nav {
  width: "100%";
}

.fa-spin {
  animation: fa-spin 4s infinite linear;
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.centered {
  display: block;
  align-content: "center";
}

nav {
  font-family: "Roboto", sans-serif;
}

.font {
  font-family: "Roboto", sans-serif;
}
